body,
html {
  width: 100%;
  height: 100%;
}

body,
h2,
h3,
h4,
h5,
h6,
span,
p {
  font-family: Arial !important;
  font-family: Arial !important;
  font-family: Arial !important;
  font-family: Arial !important;
  font-family: Arial !important;
  font-weight: 100;
  font-size: 100%;
}

/* Navigation */
#menu {
  padding: 15px;
  transition: all 0.8s;
}

#menu.navbar-default {
  background-color: #fff;
  border-color: rgba(231, 231, 231, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

#menu a.navbar-brand {
  font-family: Arial !important;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
}

#menu.navbar-default .navbar-nav>li>a {
  font-family: Arial !important;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
}

#menu.navbar-default .navbar-nav>li>a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}

#menu.navbar-default .navbar-nav>li>a:hover:after {
  width: 100%;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
  background-color: transparent;
}

.navbar-default .navbar-nav>.active>a:after,
.navbar-default .navbar-nav>.active>a:hover:after,
.navbar-default .navbar-nav>.active>a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}

.navbar-toggle {
  border-radius: 0;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}

.navbar-default .navbar-toggle:hover>.icon-bar {
  background-color: #608dfd;
}

/**********************************
NAVIGATION
***********************************/
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  background: #ededed !important;
  box-shadow: 0 0 0.5em #dddddd;
}

#header.header-scrolled,
#header.header-inner-pages {
  padding: 12px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  font-family: Arial !important;
}

#header .logo a {
  color: #5a5af3;
}

#header .logo img {
  max-height: 40px;
}

@media (max-width: 992px) {
  #header {
    padding: 12px 0;
  }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

/* @media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
    height: 80vh;
  }
}

@media (max-width: 991px) {
  #hero .hero-img {
    text-align: center;
  }
  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero {
    text-align: center;
  }
  #hero ul {
    text-align: left;
    font-size: 14px;
  }
  #hero .hero-img img {
    width: 80%;
  }
}

#hero {
  width: 100%;
  height: 100vh;
  background-image: url("assets/images/Path-3676.png");
  background-position: 50%;
  background-size: cover;
} */
/*-- banner --*/
.w3l-banner {
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 80px);
}

.w3l-banner .banner-image {
  position: absolute;
  overflow: hidden;
  background-image: url(assets/images/dsc-wave.png);
  background-size: cover;
  background-position: center;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.1);
  min-height: calc(100vh - 80px);
}

.w3l-banner .banner-content {
  display: grid;
  align-items: center;
  min-height: calc(100vh - 80px);
}

.container {
  position: relative;
  z-index: 1;
}

.section-top {
  z-index: 1080;
}

.section-top-1 {
  position: relative;
  margin-top: 40px;
}

.section-top-2 {
  position: relative;
  margin-top: 120px;
}

/* start about main */

.wave_bg {
  background-color: #f4f6f8;
}

.aboutcon1 {
  width: 100%;
  height: 850px;
  background-position: 50%;
  background-size: cover;
}

@media (max-width: 480px) {
  .reverse {
    flex-direction: column-reverse;
  }

  .aboutcon1 {
    width: 100%;
    min-height: 800px;
    background-position: 50%;
    background-size: cover;
  }
}

/* end about tmain */

/* start ypur_plan */
.ypur_plan {
  min-height: 100px;
  width: 100%;
  transform-origin: top;
  background-image: url("assets/images/p_1.png");
  background-position: center;
  background-repeat: no-repeat;
}

.ypur_plan_2 {
  background-color: #f5f5f5;
}

.ypur_plan_1 {
  min-height: 100px;
  width: 100%;
  transform-origin: top;
  background-image: url("assets/images/p_2.png");
  background-position: center;
  background-repeat: no-repeat;
}

.stline {
  margin: 20px 0;
  border: solid 1px #797979;
  width: 100%;
}

.stline_1 {
  margin: 20px 0;
  border: solid 1px #ffffff;
}

/* end ypur_plan */
/*Joined Schools*/

.item {
  border-radius: 10px;
  background-color: #00a651;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 10px 10px 10px;
  margin: 0 15px 15px 15px;
}

.item .pic {
  display: inline-block;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.item .pic img {
  width: 100%;
  height: auto;
}

.item .description {
  font-size: 25px;
  letter-spacing: 1px;
  color: #ffffff;
  line-height: 25px;
  margin-bottom: 15px;
  font-weight: 600;
}

.item .title {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #29d18b;
  margin: 0;
}

.item .post {
  display: inline-block;
  font-size: 17px;
  color: #29d18b;
  font-style: italic;
}

.owl-theme .owl-controls .owl-page span {
  border: 2px solid #2a3d7d;
  background: #fff !important;
  border-radius: 0 !important;
  opacity: 1;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls .owl-page:hover span {
  background: #29d18b !important;
  border-color: #29d18b;
}

.item-icon {
  content: "";
  display: inline-block;
  width: 47px;
  height: 20px;
  background: #de9b0b;
  position: absolute;
  top: -10px;
  right: 20px;
  transform: rotate(1deg);
  border-radius: 8px;
  z-index: -2;
}

.item-icon1 {
  content: "";
  display: inline-block;
  width: 94px;
  height: 93px;
  background: #feba26;
  position: absolute;
  top: -10px;
  right: 29px;
  border-radius: 0px 0px 8px 8px;
}

.card_join_1 {
  background-color: #f5f5f5;
  padding: 50px 0;
}

.align-self {
  align-self: center;
}

/*Joined Schools*/

.footer {
  background-image: url("assets/images/svg/footer_main.svg");
  background-color: #000000;
  background-size: cover;
  background-position: center;
  top: 0;
}

.flogo {
  /* padding: 20px; */
  color: #fff;
  margin-top: 5% !important;
}

.flogo ul li {
  list-style-type: none !important;
  line-height: 30px;
}

.footer-1 {
  border-bottom: solid 0.5px #fff;
}

.driving {
  text-align: center;
  color: #fff;
  /* height: 60px; */
  padding-top: 20px;
}

.social-media {
  margin: 0px 20px 0px 0px;
  font-size: 40px;
}

.buttom-footer1 {
  margin-left: 30px;
}

.buttom-footer {
  text-decoration: none;
  color: #ffffff;
}

.buttom-footer:hover {
  text-decoration: none;
  color: #ffffff;
}

.footer-right {
  text-align: right;
}

.footer-link {
  text-align: left;
}

.line1 {
  color: #ffffff;
}

@media (max-width: 768px) {
  .footer-right {
    text-align: center;
  }

  .footer-link {
    text-align: center;
  }

  .driving {
    text-align: center;
    color: #fff;
    min-height: 20px;
    padding-top: 10px;
  }
}

/* / Footer / */

.icon-effects-w3-1 {
  position: absolute;
  top: 90%;
  right: 0%;
}

.icon-effects-w3-2 {
  position: absolute;
  top: 12%;
  left: 6%;
}

.icon-effects-w3-3 {
  position: absolute;
  top: 0%;
  right: 0%;
}

.icon-effects-w3-4 {
  position: absolute;
  bottom: -10%;
  left: 3%;
}

.icon-effects-w3-5 {
  position: absolute;
  top: -8%;
  right: 0%;
}

.icon-effects-w3-6 {
  position: absolute;
  top: -10%;
  left: 3%;
}

@media (max-width: 768px) {
  .icon-effects-w3-1 {
    display: none;
  }

  .icon-effects-w3-2 {
    display: none;
  }

  .icon-effects-w3-3 {
    display: none;
  }

  .icon-effects-w3-4 {
    display: none;
  }

  .icon-effects-w3-5 {
    display: none;
  }

  .icon-effects-w3-6 {
    display: none;
  }
}

.number {
  margin-top: 10px;
  justify-content: end;
  display: flex;
}

.minus,
.plus {
  width: 40%;
  background: #f2f2f2;
  border-radius: 4px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

input {
  width: 60%;
  /* text-align: center; */
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.Navbar {
  font-size: 16px;
  font-weight: 400;
}

.Navbar .navbar-light .navbar-nav .nav-link {
  color: rgb(229 68 27);
}

.Navbar .navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(0 166 80);
}

.ram .MuiDropzonePreviewList-imageContainer {
  display: none;
}

.ram .MuiDropzoneArea-root {
  width: 100%;
  border: dashed;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  min-height: 100px;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
}

.ram .MuiDropzoneArea-text {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* plan card */
.Main_Card .card2 {
  border-top: 6px solid #feba26;
  min-height: 100px;
}

.Main_Card .card3 {
  border-top: 6px solid #00a651;
  min-height: 100px;
}

/* end plan card */

.plans {
  margin: 10px 0px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 20px;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.plans .plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.plans .plan {
  cursor: pointer;
  width: 48.5%;
}

.plans .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #e1e2e7;
  border-radius: 5px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.plans .plan .plan-content img {
  margin-right: 30px;
  height: 72px;
}

.plans .plan .plan-details span {
  /* margin-bottom: 10px; */
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #252f42;
}

.container .title {
  font-size: 16px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #252f42;
  margin-bottom: 20px;
}

.plans .plan .plan-details p {
  color: #646a79;
  font-size: 14px;
  line-height: 18px;
}

.plans .plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plans .plan input[type="radio"]:checked+.plan-content:after {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  background: #00a651;
  right: 15px;
  top: 15px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #00a651;
  box-shadow: 0px 0px 0px 2px #00a651;
}

.plans .plan input[type="radio"]:checked+.plan-content {
  border: 2px solid #00a651;
  background: #e7fff3;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

@media screen and (max-width: 991px) {
  .plans {
    margin: 10px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 20px 0px;
  }

  .plans .plan {
    width: 100%;
  }

  .plan.complete-plan {
    margin-top: 20px;
  }

  .plans .plan .plan-content .plan-details {
    width: 70%;
    display: inline-block;
  }

  .plans .plan input[type="radio"]:checked+.plan-content:after {
    top: 45%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@media screen and (max-width: 767px) {
  .plans .plan .plan-content .plan-details {
    width: 60%;
    display: inline-block;
  }
}

@media screen and (max-width: 540px) {
  .plans .plan .plan-content img {
    margin-bottom: 20px;
    height: 56px;
    -webkit-transition: height 0.4s;
    -o-transition: height 0.4s;
    transition: height 0.4s;
  }

  .plans .plan input[type="radio"]:checked+.plan-content:after {
    top: 20px;
    right: 10px;
  }

  .plans .plan .plan-content .plan-details {
    width: 100%;
  }

  .plans .plan .plan-content {
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

/* inspiration */
.inspiration {
  font-size: 12px;
  margin-top: 50px;
  position: absolute;
  bottom: 10px;
  font-weight: 300;
}

.inspiration a {
  color: #666;
}

@media screen and (max-width: 767px) {

  /* inspiration */
  .inspiration {
    display: none;
  }
}

h5 {
  font-size: 2.3rem;
  font-weight: 400;
  width: 100%;
}

h5 small {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0 0.2rem;
}

.price {
  font-size: 1.5rem;
  font-weight: 500;
}



.bold {
  font-weight: 500;
  margin-bottom: 8px;
}

.container21 {
  margin-top: 20px;
}

.card21 {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.20);
}

.card21 .card-primary,
.card21 .card-content {
  vertical-align: top;
}

.card21 .card-primary {
  min-width: 160px;
  background-color: #121e40;
  color: #fff;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
}

.card21 .card-content {
  position: relative;
  width: 100%;
  font-weight: 400;
  line-height: 1.25rem;
  /* padding: 10px */
}



i.left {
  float: left;
  margin-right: 16px;
  line-height: 36px;
}

.valign {
  display: flex;
  align-items: center;
  height: 60px;
}

.card123 {
  padding: 5px 10px;
}

/* strip */


/* strip */